<template>
  <div class="box">
    <div class="centertBox">
      <div class="contentBox">
        <img class="bigTitle" src="@/assets/images/title.png" alt="" />

        <div class="loginBox">
          <div class="row" style="margin-bottom: 35px">
            <div :class="[
              'login_frame_from_type',
              state.type == '账号登录' ? 'color_2' : 'color_3',
            ]" @click="state.type = '账号登录'">
              <svg style="width: 20px; height: 20px; margin-right: 10px" class="login_frame_from_icon"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" data-v-394d1fd8="">
                <path fill="currentColor"
                  d="M512 512a192 192 0 1 0 0-384 192 192 0 0 0 0 384zm0 64a256 256 0 1 1 0-512 256 256 0 0 1 0 512zm320 320v-96a96 96 0 0 0-96-96H288a96 96 0 0 0-96 96v96a32 32 0 1 1-64 0v-96a160 160 0 0 1 160-160h448a160 160 0 0 1 160 160v96a32 32 0 1 1-64 0z">
                </path>
              </svg>
              账号登录
            </div>
            <!-- <div class="rling"></div> -->
            <!-- <div
              :class="[
                'login_frame_from_type',
                state.type == '扫码登录' ? 'color_2' : 'color_3',
              ]"
              @click="state.type = '扫码登录'"
            >
              <img
                style="width: 20px; height: 20px; margin-right: 10px"
                :src="
                  state.type == '扫码登录'
                    ? require('@/assets/img/二维码.png')
                    : require('@/assets/img/二维码1.png')
                "
                alt=""
              />
              扫码登录
            </div> -->
          </div>
          <el-form style="width:100%;" @submit.native.prevent>
            <div class="row">
              <el-input ref="username" v-model="state.loginForm.username" placeholder="请输入用户名" name="username"
                type="text" />
            </div>
            <div class="row">
              <el-input ref="password" v-model="state.loginForm.password" placeholder="请输入密码" name="password"
                show-password />
            </div>
            <div class="row">
              <el-input ref="code" v-model="state.loginForm.code" :placeholder="`请输入验证码`" name="code" type="text" />
              <img @click="getCaptcha()" style="position: absolute;right: 0;
                  top: 0;
                  width: 98px;
                  height: 38px;
                  margin: 1px 1px 0 0;
                  border-radius: 4px;
                " id="verify-img" :src="state.captchaImg" />
            </div>
            <el-button type="primary" class="loginBtn" native-type="submit" @click="handleLogin">登 录</el-button>
            <!-- <div @click="tore()" class="registerText">注册账号</div> -->
          </el-form>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
} from "vue";
import { login, captcha } from "@/api";
import md5 from "js-md5";
import Header from "./header.vue";
import Footer from "./footer.vue";
import { ref, reactive, toRefs, watch, computed } from "vue";
import { useStore, mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { useRouter } from "vue-router";
export default {
  components: { Footer, Header },
  setup() {
    const _this = getCurrentInstance().proxy;
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      count: 0,
      vrUrl: false,
      waterEntityEnd: false,
      type: "账号登录",
      loginForm: {
        username: process.env.NODE_ENV === 'production' ? '' : 'admin',
        password: process.env.NODE_ENV === 'production' ? '' : 'Qwer1234',
        code: "",
        key: "",
      },
      is: false,
      user: "",
      region: "",
      captchaImg: false,
    });
    const getCaptcha = async () => {
      await captcha().then((res) => {
        // console.log(res)
        state.captchaImg = res.image;
        state.loginForm.key = res.key;
      });
    };
    const tore = ()=>{
      router.push({ path: "/register" });
    };
    const handleLogin = () => {
      let obj = Object.assign({}, state.loginForm);
      obj.password = obj.password ? md5(obj.password) : obj.password;
      getCaptcha()
      login(obj).then((res) => {
        // console.log(res)
        if (!res.error_code) {
          _this.setCookie("saber-access-token", res.access_token, 7);
          _this.setCookie("saber-refresh-token", res.refresh_token, 7);
          sessionStorage.setItem(
            "saber-access-token",
            JSON.stringify({
              data: res.refresh_token,
              dataTime: new Date().getTime(),
            })
          );
          _this.$message({
            message: "登录成功",
            type: "success",
          });
          router.push({ path: "/" });
        } else {
          _this.$message({
            message: res.error_description,
            type: "warning",
          });
        }

      });
    };
    onMounted(async () => {
      await getCaptcha();
    });
    return {
      state,
      //方法,
      handleLogin,
      getCaptcha,
      tore
    };
  },
};
</script>
<style lang="less" scoped>
.box {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .headerBox {
    width: 100%;
    height: 70px;
    -webkit-box-shadow: 0px 1px 1px rgba(128, 128, 128, 0.315);
    -moz-box-shadow: 0px 1px 1px rgba(128, 128, 128, 0.315);
    box-shadow: 0px 1px 1px rgba(128, 128, 128, 0.315);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    padding: 0 20%;
    box-sizing: border-box;

    .bigName {
      font-size: 36px;
      color: black;
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
    }

    .rightAllBtn {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 20px;
        margin-left: 10px;
        object-fit: contain;
      }
    }
  }

  .centertBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    background-image: url("../assets/images/back.png");
    background-size: cover;
    background-repeat: no-repeat;

    .contentBox {
      width: 1000px;
      position: relative;
      height: 529px;
      border-radius: 10px;
      background: #ffffff;
      margin: auto;
      max-width: 100vw;
      box-shadow: rgba(151, 168, 232, 1) 0px 1px 38px 3px,
        rgba(57, 105, 242, 1) 0px 1px 0px inset;
      // display: flex;
      // flex-direction: row-reverse;
      background-repeat: no-repeat;
      background-image: url("../assets/images/tuceng1.png");
      overflow: hidden;
      padding: 0 60px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .bigTitle {
        position: absolute;
        left: 50%;
        top: 42px;
        transform: translate(-50%, 0);
      }

      .loginBox {
        width: 310px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 120px;

        .row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin-bottom: 20px;

          .login_frame_from_type {
            width: 49%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }

          .rling {
            width: 1px;
            height: 20px;
            background: #bfbfbf;
          }
        }

        .loginBtn {
          width: 100%;
          margin-top: 50px;
          background: rgba(57, 105, 242, 1);
        }
      }
    }

    .login_frame_div {
      width: 375px;
      background: rgba(51, 153, 255, 0.1);
      border-radius: 20px;
      box-sizing: border-box;
      padding: 30px 0;
      text-align: center;

      .login_frame_div_title {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #606266;
        margin-bottom: 10px;
      }

      .login_frame_div_text {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #606266;
        margin-bottom: 30px;
      }

      .login_frame_div_button {
        font-size: 18px;
        width: 300px;
        height: 50px;
        box-shadow: 0px 5px 15px 0px rgba(0, 114, 255, 0.2),
          0px 20px 40px 0px rgba(0, 114, 255, 0.2);
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }

    .login_frame_image {
      width: auto;
      height: 100%;
    }

    .login_frame_from {
      flex-grow: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 0 30px;

      .login_frame_from_title {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #606266;
        margin-bottom: 56px;
      }

      .login_frame_from_types {
        width: 338px;
        height: 40px;
        border: 1px solid #dcdcdc;
        // border-radius: 5px;
        overflow: hidden;
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 20px;

        .login_frame_from_type {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
          z-index: 2;
          cursor: pointer;
          position: relative;
          transition: color 0.5s;
        }

        .login_frame_from_back {
          position: absolute;
          top: 0;
          width: calc(50% - 2px);
          height: calc(100% - 2px);
          // background: rgba(51, 153, 255, 1);
          border: 1px solid rgba(51, 153, 255, 1);
          transition: left 0.5s;
        }
      }

      .login_frame_from_input {
        width: 340px;
        position: relative;

        .login_frame_from_icon {
          position: absolute;
          left: 15px;
          top: 15px;
          width: 20px;
          height: 20px;
          z-index: 9;
          color: rgba(181, 181, 181, 1);
        }

        .login_frame_from_line {
          position: absolute;
          left: 48px;
          top: 13px;
          z-index: 9;
          width: 1px;
          height: 24px;
          background: #dcdcdc;
        }
      }

      .login_frame_from_is {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 340px;
        height: 20px;
        margin-bottom: 10px;
      }

      .login_frame_from_button {
        width: 340px;
        height: 50px;
        font-size: 20px;
      }

      .login_frame_from_regs {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
    }
  }
}

.color_2 {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
}

.color_3 {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #b3b3b3;
}

.allOtherWay {
  width: 338px;
  margin-top: 20px;
  color: #606266;
  display: flex;
  align-items: center;

  img {
    width: 20px;
    margin: 0 5px;
    object-fit: contain;
  }
}

.registerText {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #6487F1;
  text-align: center;
  margin-top: 22px;
  cursor: pointer;
}
.registerText:hover{
  color: gainsboro;
}
</style>